
  
  #main {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 50px;
    width: auto;
    gap: 40px;
  }


  /* Barra de navegación lateral-------------------------------------------------------------- */
  #fake-vertical-menu{
    height: 100%;
    opacity: 0;
  }

  
  #vertical-menu {
    position: fixed;
    top: 0px;
    z-index: 7;
  }

  #overlay-container{
    position: relative;
    width: 2px;
  }

  #submenu-overlay{
    transition: all ease 0.5s;
    position: absolute;
    width: 100vw;
    height: 100vw;
    z-index: -10;
    left: 15px;
  }

  #vertical-menu .main-menu-button,
  #vertical-menu .sub-menu-button {
    padding: 15px 12px 15px 12px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    display: flex;
    overflow: hidden;
    gap: 15px;
    max-height: 55px;
    text-align:left;
    transition: all ease 1s;
  }
  
  #vertical-menu .main-options ul,
  #vertical-menu .sub-menu-container ul {
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 15px;
  }
  
  #vertical-menu .main-options {
    padding-left: 7px;
    width: fit-content;
  }
  
  #vertical-menu .sub-menu-container {
    transition: all .5s ease;
    height: 100%;
    position: relative;
    right: 2px;
  }

  #vertical-menu .main-options ul li,
  #vertical-menu .sub-menu-container ul li {
    width: 185px;
    transition: width 1s;
  }

  #vertical-menu .logo-container {
    width: 100px;
    max-height: 60px;
    margin: 15px auto 0 auto;
  }

  #vertical-menu .logo-container img{
    max-height: 60px;
    min-height: 30px;
  }
  
  #vertical-menu .options-container {
    display: flex;
    height: 100vh;
    width: 220px;
  }


/* Header---------------------------------------------------------------------------------------- */

#header {
    /* width: calc(100%-90px); */
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  #header-menu {
    position: absolute;
    right: 4%;
    height: 100%;
    top: 0;
  }
  
  #user-menu{
    position: relative;
  }
  
  
/* Navigation tab ------------------------------- */
  
#navigation-tab {
    padding: 23px 50px;
    position: sticky;
    top: 70px;
    z-index: 3;
  }

  
  
  #principal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    transition: all 2s ease;
  }

  #sub-principal-container {
    width: 100%;
    transition: all 2s ease;
  }


/* Otros--------------------------------------------------------------------------- */

#btn-nueva-plantilla{
  background-color: white;
  padding: 3px;
  font-weight: 900;
  margin-top: 11px;
}

#btn-guardar-plantilla{
  margin: 15px 0;
  position: absolute;
  right: 40px;
}

  
/*MOVIL---------------------------------------------------------*/
@media (max-width: 575.98px) and (orientation: portrait) {

  #principal-container,#header{
    flex-direction: row-reverse;
  }

  #general-btn-add{
    position:fixed;
    bottom: 5%;
    right: 5%;
    z-index: 10;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }

  #header{
    padding: 20px;
    box-sizing: border-box;
  }

  #vertical-menu{
    width: 80% !important;
  }
  
   #vertical-menu .options-container{
    flex-direction: row-reverse;
    width: 100%;
   }

   #vertical-menu .options-container ul li{
    width: 100%;
   }

  #vertical-menu .main-options{
    width: 100%;
    padding-top: 95px;
  }

  #main{
    display: block;
    padding: 25px;
    box-sizing: border-box;
  }

  #toggle-btn,#toggle-x-btn{
position: absolute;
top: 20%;
right: 2%;
  }

  #navigation-tab{
    padding: 20px 20px 0px 20px;
  }

}

/*TABLET-------------------------------------------------------------------------------*/
@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  #vertical-menu.is-closed, #fake-vertical-menu{
    display: none;
  }

  #navigation-tab{
    padding: 10px 20px;
  }

}
