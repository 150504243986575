/* LOGIN------------------------------------------------------------ */

.login-container {
  padding: 50px 5%;
  box-sizing: border-box;
  gap: 10%;
  justify-content: center;
}

.login-container > div {
  padding: 0 35px 0 70px;
  display: flex;
  max-width: 38%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 11px 0px 10px -13px rgb(0 0 0 / 10%) inset;
}

.login-container .logo-container {
  width: 110px;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  height: 70vh;
  justify-content: center;
}

.login-container .form-title {
  color: var(--color-700);
  margin: 20px 0;
  text-align: center;
}

.login-container form .form-group {
  margin: 0;
  padding: 0;
}

.login-container form .form-group input {
  width: 210px;
  padding-left: 35px;
}

/* Animación boton de usuario--------------------------------------------------------- */

.user-btn {
  white-space: nowrap;
  transition: all ease 0.2s;
}

.user-btn.is-showing-options {
  height: max-content;
  padding: 7px 10px;
  transition: all ease 0.5s;
}

.user-effect-container.is-visible:after {
  content: "";
  position: absolute;
  top: 15%;
  right: -70px;
  width: 120px;
  height: 17px;
  animation: whip 1.35s 0s;
  animation-iteration-count: 1;
}

.user-effect-container.is-visible:before {
  content: "";
  position: absolute;
  top: 15%;
  right: 70px;
  width: 120px;
  height: 17px;
  animation: whip 1.35s 0s;
  animation-iteration-count: 1;
}

.user-effect-container {
  position: absolute;
  top: 103%;
  right: 30%;
  height: 40px;
  opacity: 0;
  visibility: hidden;
  width: 90px;
  transition: all 0.5s ease;
  transform: translateY(-7px);
  z-index: 11;
  overflow: hidden;
}

.user-options {
  padding: 18px;
  display: flex;
  position: absolute;
  top: 103%;
  opacity: 0;
  visibility: hidden;
  right: 0%;
  flex-direction: column;
  z-index: 10;
  min-width: 150px;
  transition: visibility ease 3s;
  transition: opacity ease 3s;
  transition: transform ease 2s;
  transform: translateY(-10px);
}

.user-effect-container.is-visible .buble-1 {
  width: 5px;
  height: 5px;
  position: relative;
  top: 0%;
  animation: bubble-falling 4s 0.5s 1;
  animation-fill-mode: forwards;
}

.user-effect-container.is-visible .buble-2 {
  width: 7px;
  height: 7px;
  position: relative;
  top: 3%;
  left: 5px;
  animation: bubble-falling 4s 0.5s 1;
  animation-fill-mode: forwards;
}

.user-effect-container.is-visible .buble-3 {
  width: 7px;
  height: 7px;
  position: relative;
  top: -25%;
  left: 50%;
  animation: bubble-falling 4s 0.5s 1;
  animation-fill-mode: forwards;
}

.user-options.is-visible {
  animation: second 1.6s 0s;
  transform: translateY(12px);
  animation-iteration-count: 1;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  gap: 18px;
}

@keyframes whip {
  0% {
    transform: scaleY(2);
  }
  50% {
    transform: scaleY(2.5);
  }
  75% {
    transform: scaleY(3);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes second {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(12px);
  }
}

@keyframes bubble-falling {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}

/* Botones ------------------------------------------------------------------------------------ */
.btn {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 7px;
  width: fit-content;
  cursor: pointer;
  transition: all 0.3s ease;
}

a.btn:hover {
  transform: scale(1.1);
}

.action-btn {
  justify-content: center;
  padding: 6px;
}

.btn-primary {
  padding: 7px 25px;
}

.alternative-btn {
  padding: 10px 30px;
}

.simple-btn {
  padding: 10px 0;
}

.more-content {
  width: fit-content;
  position: absolute;
  right: 5%;
}

.btn.table-options .Material-Icons-Outlined {
  font-size: 17px;
  color: var(--color-accent);
}

/* GRAFICOS--------------------------------------------------------------------------- */
.donut-chart-box {
  width: 25vw;
  height: 20vw;
}

.bar-chart-box {
  width: 35vw;
  padding-bottom: 10px;
  padding-right: 10%;
}

.pie-chart-box {
  width: 17vw;
  height: 13vw;
  padding-bottom: 30px;
  display: flex;
}

.line-chart-box {
  padding: 20px;
}

.chart-options {
  position: relative;
  left: 65%;
  bottom: 25px;
  height: 35px;
  border: none;
  padding: 5px;
}

.progress-bar-container {
  width: 100%;
  margin: 7px 0 20px 0;
}

.progress-bar {
  width: 50%;
  height: 10px;
}

/* Contenedores-------------------------------------------------------------------------- */

.dashboard-numbers-container {
  flex-wrap: wrap;
}

.count-registers-container {
  justify-content: space-evenly;
}

.count-registers-container .Material-Icons-Outlined {
  font-size: 35px;
  color: var(--color-700);
  align-self: flex-start;
  margin-top: 11px;
}

.count-registers-container .title {
  color: var(--color-text);
}
.count-registers-container .number {
  font-size: 17px;
  color: var(--color-text);
}
.count-registers-container .count-change {
  font-size: 13px;
  color: var(--color-accent);
}

.dashboard-numbers-container .grey-text {
  position: absolute;
  right: 20px;
  top: 30%;
  font-size: larger;
  font-weight: bold;
}

.container-blue-rounded {
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
}

.container-col-60 {
  width: 60%;
}

.container-col-30 {
  width: 30%;
}

.container-col-40 {
  width: 40%;
}

.container-col-50 {
  width: 50%;
}

.container-col-75 {
  max-width: 75%;
}

.card-img-container {
  width: 190px;
  padding: 10px;
}

.card-app-img-container {
  width: 45px;
  padding: 10px;
}

.form-container {
  margin-left: 250px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.card {
  position: relative;
  border: none;
  height: fit-content;
  text-align: left;
  margin-bottom: 50px;
  padding: 30px 35px;
  transition: all 0.5s ease;
}

.card-transparent {
  padding: 30px 10px;
  transition: all 2s ease;
}

.card-simple-grey {
  margin: 20px 0 0 0;
  padding: 8px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.circular-container-sm {
  width: 37px;
  height: 37px;
  border-radius: 100%;
}

.context-actions-container {
  justify-content: flex-end;
  padding-right: 54px;
  position: fixed;
  right: 0;
  top: 78px;
  z-index: 3;
}

.container-col-whole-space {
  width: 100%;
  max-width: 100%;
}

.error-image-container img {
  max-width: 170px;
}

.guide-container {
  display: flex;
  flex-direction: column;
  gap: 75px;
}

.guide-container section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 100px;
  gap: 12px;
  text-align: center;
}

.guide-container img {
  border-radius: 30px;
}

.guide-introduction {
  padding: 50px 100px;
}
/* SECCION DE TABLAS -------------------------------------------------------------------- */

#main.register {
  padding: 0 35px;
}

.table-section-name {
  padding-left: 20px;
  text-align: left;
}
.table-section-subname {
  padding-left: 20px;
  text-align: left;
  margin-top: 10px;
}

.table-container {
  min-width: 100%;
  overflow-y: visible;
  overflow-x: scroll;
  max-width: 75vw;
  min-height: 100%;
  position: relative;
}

.table-container.table-scroll {
  max-height: 400px;
}

.plantillas-card .table-container {
  overflow: visible;
}

.table-container::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.table {
  width: 100%;
  max-width: 100%;
  padding: 25px 12px;
}

.table-profesores td {
  border: 1px solid #d9d8e0;
}

.table td {
  padding: 15px 10px 15px 10px;
  position: relative;
  box-sizing: border-box;
  max-width: 10%;
}
.table tr th.first-header-row {
  text-align: center;
  background-color: var(--color-100);
}

.table tr th {
  padding: 15px 10px 15px 10px;
  position: relative;
  box-sizing: border-box;
  max-width: 10%;
  overflow: hidden;
}

.registers-btn-action {
  display: flex;
  align-items: center;
  padding: 10.5px 12px;
  border: none;
  white-space: nowrap;
  gap: 10px;
}

.registers-input {
  padding: 12.5px;
  border: none;
}

.superior-right-item {
  position: absolute;
  top: 20px;
  right: 20px;
  top: 12px;
  right: 55px;
}

.dataTable-bottom {
  position: relative;
  padding: 0 20px;
}

.dataTable-bottom .btn {
  padding: 5px 10px;
}

.dataTable-dropdown {
  border: none;
  margin: 0 10px;
}

.dataTable-pagination {
  position: absolute;
  right: 2%;
}
.pagination-numbers-container {
  max-width: 220px;
  overflow: hidden;
  padding-left: 70px;
}

.pagination-numbers-scroll {
  display: flex;
  position: relative;
}

.pagination-numbers-scroll .btn {
  min-width: 35px;
  max-width: 35px;
  justify-content: center;
}

.register-options-container {
  position: fixed;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  cursor: none;
}

.view-options-container {
  position: absolute;
  top: 110%;
}

.register-options-container button,
.view-options-container button {
  padding: 15px 30px 15px 10px;
}

.register-options-container button {
  text-align: left;
}

.filters-container {
  position: absolute;
  top: 111%;
  left: 0;
  padding: 0px;
  z-index: 7;
  max-height: 315px;
  overflow: scroll;
}

.filters-header {
  padding: 10px 20px;
}

.filters-container .filters-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 20px;
  padding: 22px;
}

.filters-container .filters-list button {
  position: relative;
  width: 100%;
  padding-right: 35px;
}

.material-icons.active-filter-icon {
  position: absolute;
  right: 0;
}

/* MOSAICOS------------------------------------------------------------------------------- */

.mosaic-container {
  padding: 30px 20px;
  flex-wrap: wrap;
  gap: 6%;
}

.mosaic-card {
  width: 28%;
  position: relative;
  margin-bottom: 30px;
}

.mosaic-img-container {
  height: 140px;
}

.mosaic-img-container img {
  height: 100%;
}

.mosaic-info {
  padding: 10px;
}

.mosaic-info .options {
  position: absolute;
  right: 5px;
}

/* MODAL------------------------------------------------------------------------------ */

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: fit-content;
  min-width: 50%;
  height: fit-content;
  position: relative;
  transition: all ease 0.7s;
  max-height: 72%;
  max-width: 68%;
  overflow: hidden;
  transition: width 0.5s ease;
  padding-bottom: 40px;
}

.modal-header {
  padding-left: 0;
  margin: 0;
  padding-bottom: 10px;
}

.modal-header .modal-title {
  margin: 0;
}

.modal-body {
  overflow-y: auto;
  max-height: 360px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--color-gray);
  border-radius: 10px;
}

.modal-body::-webkit-scrollbar {
  width: 10px;
}

.modal-bottom {
  height: fit-content;
  width: 100%;
  position: relative;
  right: 31px;
  padding: 20px 25px 10px;
  justify-content: right;
  display: flex;
}

.modal-delete .modal-bottom {
  right: 15px;
  padding: 10px;
}
.bottom-on-delete {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal .warning-icon {
  height: 150px;
  width: auto;
}

.modal .form-btn-group {
  bottom: 18px;
}
.modal-bottom .form-btn-group {
  position: static;
}

.form-btn-group img {
  height: 100%;
  width: 100%;
}

.img-spinner-registers {
  max-width: 45px;
}

.modal-info {
  text-align: center;
  padding-top: 25px;
  margin: auto;
}

.loading-container {
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  z-index: 11;

  transition: all ease 1.4s;
}

.activities-quantities-container {
  flex-wrap: wrap;
}

/* LOADING--------------------------------------- */

.loading-container img {
  height: 100%;
  scale: 0.8;
}

/* FORMULARIO------------------------------------------------------------------------------- */

.note-editing-area {
  background-color: white;
}

.form-card {
  padding: 40px;
  display: flex;
  overflow: hidden;
  min-height: 60vh;
  position: relative;
}

.step {
  padding: 10px 15% 10px 0;
  text-align: left;
  position: relative;
}

.form-title {
  margin-top: 40px;
  text-align: left;
}

.form-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.form-group {
  display: block;
  padding-top: 15px;
  margin-right: 40px;
  text-align: left;
}

.form-group.form-group-long {
  display: block;
  width: 470px;
  padding-top: 25px;
  margin-right: 40px;
  text-align: left;
}

.form-group-large {
  width: 470px;
}

#form-plantilla-container .form-group {
  margin-right: 0;
}

.form-group input:not([type="checkbox"], [type="radio"]),
.form-group select {
  height: 32px;
  width: 100%;
  padding: 5px 30px 5px 10px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-group div {
  position: relative;
}

.to-right {
  right: 5%;
}

.input-symbol,
.red-icon {
  position: absolute;
  bottom: 26%;
  left: 7px;
}

.is-required .input-symbol.required-symbol,
.is-required .required-symbol.red-icon {
  right: 8px;
  bottom: 27%;
  left: auto;
}

.is-required.select-required .input-symbol.required-symbol,
is-required.select-required .required-symbol.red-icon {
  right: 18px;
  bottom: 27%;
}

.file-input-container {
  position: relative;
  display: flex;
  height: 35px;
  max-width: 225px;
  vertical-align: middle;
  justify-content: center;
  margin: 6px 0;
}

.file-input-name {
  position: relative;
  right: 6px;
  border-radius: 7px;
  padding: 3px 10px;
  cursor: pointer;
  border: var(--color-gray) 2px solid;
}

.file-input-name:focus {
  outline: none;
}

.btn-file {
  background: var(--color-primary);
  padding: 5px 7px;
  z-index: 2;
}

.btn-file .Material-Icons-Outlined {
  color: white;
}

.photo-load-container {
  position: absolute;
  right: 40px;
}

.form-group-photo {
  width: 55px;
  min-width: 55px;
  height: 55px;
  position: relative;
  overflow: hidden;
}

.form-group-photo img,
.form-group-photo .button-load-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
}

.form-group-photo .button-load-img {
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  cursor: pointer;
}

.form-group-photo input[type="file"] {
  opacity: 0;
  cursor: none;
  height: 0.5px;
}

.form-btn-group {
  position: absolute;
  bottom: 35px;
  display: flex;
  gap: 20px;
  z-index: 1;
  max-width: 285px;
}

.form-toast {
  position: absolute;
  padding: 12px 15px;
  width: 20%;
  min-width: 190px;
  max-width: 20%;
  bottom: 130px;
  right: 50px;
  display: flex;
  gap: 17px;
  opacity: 0;
  visibility: hidden;
  align-items: center;
  transform: translateX(50px);
}

.error-page {
  max-height: 100vh;
  min-height: 100vh;
  padding: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.error-page img {
  max-width: 40%;
  margin-bottom: 15px;
}

.truncate-text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-results-container {
  width: 100%;
  padding: 4% 20%;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 20px;
}

.no-results-container img {
  width: 50%;
}

.array-btn-delete {
  position: relative;
  bottom: 18px;
}

.fake-switch {
  position: relative;
  display: inline-block;
  width: fit-content;
  height: 18px;
  padding: 0px 3px 2px 8px;
}

.fake-switch .material-icons {
  margin-left: 3px;
}

.fake-switch:after {
  content: "";
  position: absolute;
  width: 40%;
  height: 15px;
  top: 3px;
  left: 10%;
  transition: all 0.5s;
}

.fake-switch span {
  margin-right: 3px;
}

.checkbox-switch:checked + .fake-switch::after {
  left: 50%;
}

/* Checkbox vanished */
.checkbox-switch {
  display: none;
}

.switch-container {
  width: fit-content;
  display: block;
  text-align: left;
}

/*SETTINGS---------------------------------------------------*/
.nav-settings-container {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
}

.nav-item-settings {
  display: flex;
  float: left;
  padding: 0px 15px 7px 15px;
  align-items: stretch;
  cursor: pointer;
}

.logo-frame {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  height: 100%;
  width: 100%;
  background-color: white;
}

.logo-frame > img {
  width: 15vw;
  height: 15vw;
}

.pop-container .switch-container,
.app-notification-container .switch-container {
  display: block;
  text-align: left;
  position: absolute;
  margin-bottom: 6px;
  right: 5%;
  bottom: 10%;
  margin-right: 0;
}
.app-switch-container {
  width: fit-content;
  display: block;
  text-align: center;
  margin-bottom: 6px;
}

.pop-col {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 60px;
}

.pop-label {
  margin-left: 5px;
  padding-bottom: 10px;
  font-size: 14px;
}
.app-label {
  margin-left: 5px;
  padding-bottom: 35px;
}

.notification-image-container {
  background: linear-gradient(
    to right,
    var(--color-300),
    var(--color-200),
    var(--color-100)
  );
  border: 0px solid;
  border-radius: 10px;
  align-items: center;
  padding: 40px 85px;
  margin-right: 30px;
}

.notification-image-container i {
  font-size: 60px;
  color: var(--color-accent);
}

.app-notification-container {
  width: 100%;
  margin-top: 20px;
  padding-top: 5px;
  position: relative;
  background-color: var(--color-bg-variant);
  display: flex;
  border-radius: 4px;
  gap: 10%;
}

.app-switch-container {
  width: fit-content;
  display: block;
  text-align: left;
  margin-bottom: 6px;
  position: absolute;
  right: 40px;
}

.fake-switch-unlabeled {
  width: 30px;
}

.fake-switch-unlabeled::after {
  width: 30%;
  height: 12px;
  top: 4px;
}

.checkbox-switch:checked + .fake-switch-unlabeled::after {
  left: 60%;
}

.pop-container {
  width: 370px;
  margin-top: 15px;
  padding: 10px 15px 0px 15px;
  align-items: center;
  position: relative;
  display: flex;
  border: 1px solid #e3e3e3;
  border-radius: 3.5px;
  background-color: var(--color-bg-variant);
}

.notification-card {
  margin-left: 65px;
  padding: 70px 60px;
  height: auto;
  margin-bottom: 0;
}

.settings-content {
  padding-left: 30px;
  padding-right: 50px;
  width: 100%;
}

.settings-register .form-title .form-subtitle {
  font-size: 16px;
  color: grey;
}

.settings-register .photo-load-container {
  position: static;
}

.settings-register .form-title {
  margin-top: 30px;
  font-size: 24px;
}

.plantillas-card {
  margin-top: 50px;
  max-height: 355px;
}

.scroll-view {
  overflow-y: auto;
}

.scroll-view::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.scroll-view::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 10px;
}

.scroll-view::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/*Logs*/
.log {
  text-align: start;
  display: flex;
  align-items: center;
  width: 60%;
  background-color: white;
  padding: 10px;
  margin: 2px;
  position: relative;
}

.log > td {
  padding: 5px;
  min-width: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.main-logs {
  padding: 0 0 0 5%;
  width: 90%;

  overflow: hidden;
}
.vertical-line {
  margin-top: 10px;
  border-left: 2px solid black;
  height: 30px;
  position: relative;
  left: 50%;
}
.date-logs {
  margin-top: 5px;
}

.log-row-campo {
  color: #2084af;
  background-color: #d2e6ef;
  padding: 4px 10px 4px 0px;
  border-radius: 10px;
  width: 200px;
}

.table-log {
  table-layout: fixed;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-bg-variant);
  border-spacing: 10px 10px;
  border-radius: 10px;
  padding: 5px;
}

.low-row {
  margin-bottom: 10px;
}
.log-row-usuario {
  color: #2084af;
  width: 20px;
}
.log-row-icon {
  width: 20px;
}
.log-row-anterior {
  color: #7e7e7e;
  background-color: #f2d5d3;
  text-decoration: line-through;
  padding: 4px 10px 4px 4px;
  border-radius: 10px;
  width: 200px;
}
.log-row-nuevo {
  background-color: #d3f2d8;
  padding: 4px 10px 4px 4px;
  border-radius: 10px;
  width: 200px;
}
.detail-settings > div {
  width: 100%;
}

.detail-settings h3 {
  font-size: 20px;
  font-family: var(--fuente-bold);
  color: var(--color-accent);
  text-align: left;
  display: flex;
  align-items: center;
}

.detail-settings hr {
  width: 40vw;
}

.detail-settings {
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.form-settings {
  padding-bottom: 35px;
}

/*Usuarios*/

/*MOVIL---------------------------------------------------------*/
@media (max-width: 575.98px) and (orientation: portrait) {
  .error-page {
    justify-content: center;
  }

  .modal-info form {
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .modal-info form section {
    width: 100%;
  }

  .logo-frame > img {
    width: 20%;
    height: 20%;
    position: static;
  }

  .error-page img {
    max-width: 100%;
  }
  .login-container {
    flex-wrap: wrap;
  }

  .login-container .logo-container {
    position: relative;
    left: 0;
  }

  .login-container > div {
    display: none;
  }

  .count-registers-container {
    overflow-x: auto;
  }

  .container-col-60,
  .container-col-30,
  .container-col-40,
  .container-col-50,
  .container-col-75 {
    width: 100%;
    max-width: 100%;
  }

  .card {
    padding: 30px 20px;
    max-width: 90%;
  }

  .card.flex-container {
    flex-wrap: wrap;
  }

  .activities-quantities-container {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .activities-quantities-container .container-blue-rounded {
    width: 90px;
    height: 90px;
    padding: 10px;
    flex-direction: column;
    margin: 10px;
  }

  .activities-quantities-container span {
    text-align: center;
  }

  .pie-chart-box {
    flex-direction: column;
  }

  .chart-options {
    position: static;
    margin-bottom: 15px;
  }

  .logo-container {
    display: flex;
    width: 62px;
    align-self: center;
    position: absolute;
    left: 20px;
  }

  #main.register {
    padding: 0 20px;
  }

  #navigation-tab .flex-container {
    flex-wrap: wrap;
  }

  .table-container {
    overflow-x: scroll;
  }

  .registers-btn-action {
    width: 100%;
  }

  .dataTable-bottom {
    flex-direction: column;
  }

  .dataTable-bottom div {
    position: static;
  }

  .mosaic-container {
    padding: 20px 0;
  }

  .mosaic-card {
    width: 45%;
  }

  .registers-input {
    order: -2;
    width: 100%;
  }

  .superior-right-item {
    position: initial;
  }

  .icon-btn-on-mobile {
    width: 42px;
    overflow: hidden;
  }

  #btn-change-view,
  #btn-filters {
    order: -1;
  }

  .bar-chart-box,
  .pie-chart-box,
  .donut-chart-box {
    width: 100%;
    height: 100%;
  }

  .form-steps-container {
    display: flex;
    position: relative;
    margin-top: 37px;
  }

  .form-steps-container .form-steps {
    display: flex;
    gap: 20%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  .form-steps-container .form-meter .progress-bar {
    width: calc((100% / 3) * 2);
    height: 5px;
  }

  .form-steps-container .step-mobile {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-steps-container .form-meter {
    width: 100%;
    height: 5px;
  }

  .form-card {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .form-group {
    width: 100%;
    margin-right: 0;
  }

  .form-btn-group {
    width: 100%;
    justify-content: center;
    position: static;
    padding-top: 60px;
  }

  .form-title {
    margin: 0;
  }

  .modal {
    width: 100%;
    margin: 15px;
    padding-bottom: 0;
  }

  .modal-body {
    max-height: 90%;
    min-height: fit-content;
  }

  .form-group input[type="text"],
  .form-group select {
    height: 25px;
    min-width: 100%;
    box-sizing: border-box;
    height: 40px;
  }

  .photo-load-container {
    position: relative;
    right: 0;
    flex-direction: column;
  }

  #vertical-menu .main-options ul,
  #vertical-menu .sub-menu-container ul {
    padding: 0;
  }

  .sub-menu-container ul,
  .sub-menu-container > button {
    position: relative;
    top: 12%;
  }

  .filters-container {
    top: 45%;
  }

  .context-actions-container {
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;
    position: static;
  }

  .nav-settings-container {
    overflow: scroll;
  }

  .nav-settings-container .nav-content {
    padding: 0;
  }

  .settings-content {
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
  }

  .settings-content .flex-container {
    flex-direction: column;
  }

  .settings-menu-mobile {
    display: flex;
    gap: 10px;
  }

  .notification-card {
    margin-left: 0;
    margin-top: 30px;
  }

  .main-logs {
    zoom: 50%;
    font-size: 30px;
  }

  .pop-container {
    width: 305px;
  }
}

/*TABLET-------------------------------------------------------------------------------*/

@media (min-width: 576px) and (max-width: 991.98px) and (orientation: portrait) {
  .login-container {
    flex-wrap: wrap;
  }

  .login-container > div {
    display: none;
  }

  .login-container .logo-container {
    position: relative;
    left: 0;
  }

  .login-container #main {
    padding: 20% 0;
  }

  .form-group label,
  input {
    font-size: 17px;
  }
  .registers-btn-action {
    font-size: 16px;
  }

  .container-col-75 {
    width: 100%;
    max-width: 100%;
  }

  #main.register {
    padding: 0px 35px;
    justify-content: center;
  }

  #vertical-menu.is-closed,
  #fake-vertical-menu {
    display: none;
  }

  .table-container {
    overflow-x: scroll;
  }

  .form-card {
    flex-direction: column-reverse;
    gap: 50px;
    align-items: center;
  }

  .card {
    padding: 30px 20px;
    max-width: 90%;
    height: fit-content;
    min-height: 25%;
  }

  .form-group {
    margin-right: 35px;
  }

  .form-flex,
  .form-title {
    padding: 15px;
  }

  .form-steps-container {
    padding: 20px;
  }

  .photo-load-container {
    position: relative;
    flex-direction: column;
    right: 0;
  }

  .nav-item-settings {
    padding: 0px 2px 10px 2px;
  }

  .settings-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .settings-content .form-group {
    margin-right: 0;
  }

  .notifications-container {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .settings-content .notification-title {
    min-width: 100%;
  }

  .settings-content .form-title {
    width: 180px;
    text-align: center;
  }

  .notification-card {
    flex-direction: column;
    margin: 0;
    max-width: 50%;
  }

  #btn-guardar-plantilla {
    font-size: 16px;
  }
}
