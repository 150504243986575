@font-face {
  font-family: "fuente-regular";
  src: local("Mulish-Regular"), url("../Fonts/Mulish-Regular.ttf");
  font-display: block;
}

@font-face {
  font-family: "fuente-medium";
  src: local("Mulish-Medium"), url("../Fonts/Mulish-Medium.ttf");
  font-display: block;
}

@font-face {
  font-family: "fuente-bold";
  font-weight: 700;
  src: local("Mulish-Bold"), url("../Fonts/Mulish-Bold.ttf");
  font-display: block;
}
@font-face {
  font-family: "fuente-black";
  src: local("Mulish-Black"), url("../Fonts/Mulish-Black.ttf");
  font-display: block;
}

@font-face {
  font-family: "material-icons";
  src: local("MaterialIcons-Regular"),
    url("../Fonts/MaterialIcons-Regular.ttf") format("truetype");
  font-display: block;
}

@font-face {
  font-family: "material-icons-outlined";
  src: url("../Fonts/MaterialIconsOutlined-Regular.ttf") format("truetype");
  font-display: block;
}

body {
  --color-100: #c9e1ff;
  --color-200: #c4ddff;
  --color-300: #b5d6ff;
  --color-400: #75b2fc;
  --color-600: #2e83ea;
  --color-700: #2771ca;
  --color-800: #1158b0;

  --color-primary: #006aea;
  --color-secondary: #c4deff;
  --color-accent: #00479b;

  --color-dark-text: var(--color-accent);
  --color-layout: white;
  --color-bg: #f1f5fa;
  --color-bg-variant: white;
  --color-bg-input: white;
  --color-clear-grey: #f9f9fa;
  --color-gray: #a7a7a7;
  --color-selected: var(--color-200);

  --skeleton-first: hsl(200, 20%, 80%);
  --skeleton-second: hsl(228, 20%, 95%);
  --skeleton-third: hsl(219, 20%, 80%);

  --color-text: #5d5d5d;

  --color-overlay: rgb(122 128 139 / 56%);
}

body.dark-mode {
  --color-100: #c9e1ff;
  --color-200: #aed3ff;
  --color-300: #95c3fa;
  --color-400: #75b2fc;
  --color-600: #2e83ea;
  --color-700: #2771ca;
  --color-800: #5ea7ff;
  --color-dark-text: #bddfe1;

  --color-primary: #2f8dff;
  --color-secondary: #9cc8ff;
  --color-accent: #8dbcff;
  
  --color-layout: var(--color-bg-variant);
  --color-clear-grey: hsl(225, 9%, 26%);

  --color-bg: #1d1f23;
  --color-bg-variant: #31353c;
  --color-selected: #1c5eb0;

  --color-bg-input: #6c6d78;
  --color-gray: #a7a7a7;

  --skeleton-first: hsl(220, 7%, 18%);
  --skeleton-second: hsl(226, 8%, 30%);
  --skeleton-third: hsl(220, 3%, 17%);
  --color-text: #f8f9fc;
  --color-overlay: rgba(39, 42, 47, 0.8);
}

.Material-Icons-Outlined {
  font-family: "material-icons-outlined";
  font-size: 25px;
  font-style: normal;
  pointer-events: none;
}

.material-icons {
  font-family: "material-icons";
  font-size: 20px;
  font-style: normal;
  pointer-events: none;
}

.fsize-12 {
  font-size: 12px;
}

.blue-text {
  color: var(--color-800);
  margin: 3px;
}

.dark-text {
  color: var(--color-dark-text);
  margin: 3px;
}

.grey-text {
  color: var(--color-gray);
  margin: 3px;
}

.red-icon {
  color: #e74747;
}

.bold {
  font-family: fuente-bold;
  font-weight: 700;
}

.activities-quantities-container span {
  font-size: 16px;
}

.error-page {
  background-color: var(--color-bg-variant);
}

/* Login ------------------------------------ */
.login-container h1 {
  font-weight: 800;
  font-size: 30px;
}

.login-forgot-password {
  color: var(--color-primary);
}

/* Botones ------------------------------------------------------------------------------------ */

.btn {
  font-family: "fuente-regular";
  border-radius: 6px;
  color: var(--color-text);
}

.user-options .btn:hover{
  color:var(--color-700);
}

.action-btn {
  background-color: var(--color-700);
  border-radius: 100%;
  font-size: 18px;
  color: var(--color-bg-variant);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg-variant);
  font-weight: 600;
}
.S9gUrf-YoZ4jf{
  outline: 5px solid var(--color-400);
  border-radius: 5px;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  color: #1d1f23;
  font-weight: 600;
}

.btn-primary:hover {
  background-color: var(--color-600);
}

.alternative-btn {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.simple-btn {
  color: var(--color-primary);
}

.simple-btn .material-icons {
  font-size: 25px;
}

#toggle-btn,
#toggle-x-btn {
  color: var(--color-primary);
}

/* Barra de navegación lateral-------------------------------------------------------------- */

#vertical-menu {
  background-color: var(--color-layout);
}

.main-options ul .main-menu-button {
  font-size: 14px;
}

.sub-menu-container ul li {
  font-size: 14px;
}

#submenu-overlay {
  background: var(--color-overlay);
}

.main-menu-button,
.sub-menu-button {
  font-family: "fuente-regular";
  color: var(--color-accent);
  border-radius: 10px;
}

.sub-menu-container {
  background-color: var(--color-secondary);
}

.is-selected {
  background-color: var(--color-selected);
}

/* GRAFICOS--------------------------------------------------------------------------- */

.chart-options {
  background-color: var(--color-bg);
  font-family: fuente-regular;
}

.progress-bar-container {
  background-color: var(--color-bg);
  border-radius: 20px;
}

.progress-bar {
  background-color: var(--color-primary);
  border-radius: 20px;
}

/* Contenedores-------------------------------------------------------------------------- */

.container-blue-rounded {
  background-color: #a7e4e9;
  border-radius: 10px;
}

.container-blue-rounded i {
  font-size: 32px;
}

#navigation-tab {
  background-color: var(--color-bg);
}

#navigation-tab hr {
  border-color: var(--color-bg-variant);
  opacity: 0.4;
}

.card-transparent {
  border-bottom: 1px solid var(--color-bg);
}

.card-simple-grey {
  background-color: var(--color-bg);
  border-radius: 10px;
}

.card {
  background-color: var(--color-bg-variant);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 8px 0px;
}

/* Header---------------------------------------------------------------------------------------- */

#header {
  background-color: var(--color-layout);
}

.user-btn.is-showing-options {
  border-radius: 100px;
  background-color: var(--color-secondary);
}

.user-effect-container.is-visible:after {
  border-radius: 100px;
  box-shadow: 0px 0px 0px 15px var(--color-secondary);
}

.user-effect-container.is-visible:before {
  border-radius: 100px;
  box-shadow: 0px 0px 0px 15px var(--color-secondary);
}

.user-options {
  background-color: var(--color-secondary);
  border-radius: 15px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.user-effect-container.is-visible .buble-1,
.user-effect-container.is-visible .buble-2,
.user-effect-container.is-visible .buble-3 {
  background-color: var(--color-secondary);
  border-radius: 50%;
}

/* SECCION DE TABLAS -------------------------------------------------------------------- */

.table-section-name {
  font-size: 20px;
}

.table {
  font-size: 13px;
  color: var(--color-text);
}

.table td {
  font-family: "fuente-medium";
}

.table thead td,
table thead th {
  font-family: "fuente-bold";
  color: var(--color-dark-text);
}

.registers-btn-action {
  background-color: var(--color-bg-variant);
  border-radius: 7px;
  font-family: "fuente-regular";
}

.registers-btn-action.btn-primary{
  background-color: var(--color-primary);
}

.registers-input {
  background-color: var(--color-bg-variant);
  border-radius: 7px;
  font-family: "fuente-regular";
  box-shadow: rgba(50, 50, 165, 0.15) 0px 1px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.table > tbody > tr:nth-child(odd) > td,
.table > tbody > tr:nth-child(odd) > th {
  background-color: var(--color-clear-grey);
}

.dataTable-bottom {
  color: var(--color-gray);
  font-size: 13px;
}

.dataTable-dropdown {
  color: var(--color-gray);
}

.register-options-container,
.view-options-container {
  background-color: var(--color-bg-variant);
  border: 1px solid #ececec;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.filters-container {
  border: 1px solid #d6d5d5;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.filters-header {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
}

.filters-container .filters-list button {
  font-family: "fuente-regular";
  color: var(--color-accent);
}

.material-icons.active-filter-icon {
  font-size: 16px;
}

/* MOSAICOS------------------------------------------------------------------------------- */

.mosaic-card {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid var(--color-bg);
}

.mosaic-info {
  background-color: var(--color-bg);
}

.mosaic-info .options i {
  color: var(--color-gray);
}

.mosaic-title {
  font-family: "fuente-bold";
  color: var(--color-accent);
  font-size: 15px;
}

.mosaic-subtitle {
  font-family: "fuente-medium";
  font-size: 13px;
}

/* MODAL------------------------------------------------------------------------------ */

.overlay {
  /* background: rgba(149, 149, 149, 0.559); */
  background: var(--color-overlay);
}

.modal {
  border: 1px solid #d6d5d5;
}

.modal-delete p {
  font-size: 14px;
}

.modal-header {
  border-bottom: 1px solid lightgrey;
}

.modal-header .modal-title {
  font-weight: 500;
}

.modal-bottom {
  background: var(--color-bg-variant);
}

.loading-container {
  background-color: var(--color-bg-variant);
}

/* FORMULARIO------------------------------------------------------------------------------- */

.step {
  font-size: 14px;
  color: var(--color-gray);
  border-bottom: 5px solid var(--color-gray);
}

.form-title {
  font-size: 20px;
  color: var(--color-accent);
}

.form-group {
  font-family: "fuente-medium";
  font-size: 14px;
}

.form-group input,
.table td input,
.form-group select {
  border-radius: 5px;
  font-family: "fuente-regular";
  border: 1px solid var(--color-gray);

}
.table td input{
  min-height: 20px;
  background-color: transparent;
  max-width: 100px;
}


.input-symbol {
  color: var(--color-gray);
}

.form-group-photo {
  border-radius: 50%;
  border: 5px solid var(--color-primary);
}

.photo-load-container label {
  font-weight: bolder;
}

.form-btn-group button {
  font-size: 14px;
}

.form-group-photo label {
  font-weight: 700;
  color: var(--color-accent);
}

.form-group-photo .button-load-img {
  background: rgba(40, 40, 40, 0.372);
  border-radius: 50%;
  font-size: 15px;
  color: #eae9e9;
}

.form-toast {
  background: var(--color-bg-input);
  border: 1px solid #c9c9c9;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.form-toast .material-icons {
  color: var(--color-700);
  font-size: 28px;
}

.form-toast .toast-title {
  font-weight: 600;
  font-size: 16px;
}

.form-toast .toast-message {
  font-weight: 400;
  font-size: 14px;
}

.fake-switch {
  background-color: var(--color-bg-variant);
  border-radius: 30px;
  border: 1px solid var(--color-gray);
}

.fake-switch .material-icons {
  font-size: 14px;
}

.fake-switch:after {
  border-radius: 40%;
  background-color: var(--color-primary);
}

.fake-switch span {
  font-family: "fuente-bold";
  font-size: 12px;
}

.checkbox-switch:checked + .fake-switch::after {
  background-color: var(--color-bg-variant);
}

/* Checkbox checked toggle label bg color */
.checkbox-switch:checked + .fake-switch {
  background-color: var(--color-primary);
}
.checkbox-switch:checked + .fake-switch span {
  color: var(--color-bg-variant);
}

p.is-invalid, span.is-invalid,
.material-icons.is-invalid {
  color: #e74747;
}

span.is-invalid{
  font-size: 12px;
}

input.is-invalid,
select.is-invalid {
  border-color: #e74747;
}

.form-group .grey-text {
  font-size: 12px;
}

.error-info {
  font-size: 12px;
  height: 16px;
  margin: 1px;
  vertical-align: top;
}

/* SKELETON---------------------------------------------- */
.skeleton-parent {
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: var(--color-bg-variant);
  box-sizing: border-box;
  z-index: 2;
  border-radius: 20px;
  position: absolute;
}

.skeleton-parent > div {
  width: 50%;
}

.skeleton-parent .whole-space,
.skeleton-parent .whole-space .skeleton-text {
  width: 98%;
  flex-wrap: wrap;
}

.skeleton-picture {
  width: 100%;
  position: relative;
  height: 35%;
  animation: skeleton-loading 1.5s infinite;
  margin: 20px 20px 20px 0;
  background-color: var(--color-clear-grey);
  border-radius: 20px;
}

.skeleton-parent .mosaic-container .skeleton-picture {
  width: 28%;
  position: relative;
  margin-bottom: 30px;
  height: 190px;
  margin: 0;
}

.skeleton-text {
  width: 70%;
  height: 5%;
  position: relative;
  margin: 20px 0 15px 15px;
  background-color: var(--color-clear-grey);
  animation: skeleton-loading 1.5s infinite;
  border-radius: 5px;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--skeleton-first);
  }
  75% {
    background-color: var(--skeleton-second);
  }
  100% {
    background-color: var(--skeleton-third);
  }
}

/*SETTINGS---------------------------------------------------*/
.nav-settings-container {
  background-color: var(--color-clear-gray);
}
.nav-item-settings {
  color: var(--color-accent);
  font-size: 14px;
}
.nav-item-settings:hover {
  color: var(--color-400);
  border-color: var(--color-400);
}
.settings-tab-selected {
  border-bottom: solid var(--color-primary);
  color: var(--color-primary);
}

@media (max-width: 575.98px) and (orientation: portrait) {
  .activities-quantities-container .container-blue-rounded .material-icons {
    font-size: 35px;
  }

  #toggle-btn .material-icons {
    font-size: 28px;
  }

  .form-steps-container .form-meter {
    background-color: #e2e2e2;
    border-radius: 10px;
  }

  .step-mobile {
    border-radius: 50%;
    background-color: #e2e2e2;
    color: #888888;
    font-family: "fuente-medium";
  }

  .form-meter .progress-bar {
    background-color: var(--color-primary);
  }

  .main-options ul .main-menu-button {
    font-size: 16px;
  }

  .is-selected {
    background-color: transparent;
    color: var(--color-600);
    font-weight: 500;
  }

  #general-btn-add .material-icons {
    font-size: 33px;
  }

  .sub-menu-button {
    font-size: 17px;
  }

  .modal {
    border-radius: 20px;
  }
}
