body {
    margin: 0;
    min-width: 100%;
    background-color: var(--color-bg);
    font-family: fuente-regular;
  }
  
  img {
    width: 100%;
    object-fit: cover;
  }

  button img{
    max-width: 25px;
  }
  
  li {
    list-style-type: none;
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
    color: var(--color-text);
    font-size: 13px;
  }

  a:hover{
    color: var(--color-600);
  }

  input, select{
    background-color: var(--color-bg-input);
    color:var(--color-text);
  }

  input::placeholder{
    color:var(--color-gray)
  }
  
  span,p,h1,h2,h3,h4,h5,label{
    color:var(--color-text);
  }
  
  input:focus,select:focus{
    outline: 1px solid var(--color-gray);
  }